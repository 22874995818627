import React from 'react';
import { mapStatusToChipClass, mapStatusToLabel, mapStatusToChipIconColor, mapMedicalDocumentsStatusToChipClass, mapMedicalDocumentsStatusToLabel } from '../../common';
import { AutoAwesome, EditOffOutlined, InfoOutlined } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { deepPurple } from '@mui/material/colors';

const StatusChip = ({ documentStatus, showInfoIcon, showEditOffIcon, type }) => {
  const setChipIcon = (status) => {
    if (status === 'AwaitingMedicalExtraction' || status === 'MedicalsInProgress' || status === 'AI processing') {
      return (
        <AutoAwesome fontSize='small' color={deepPurple[900]} />
      )
    } else if (status === 'DocumentArchived') {
      return undefined
    } else {
      return <InfoOutlined color={mapStatusToChipIconColor(documentStatus)} />
    }
  }

  return (
    <Chip
      className={
        type == 'document' ? mapStatusToChipClass(documentStatus) :
          mapMedicalDocumentsStatusToChipClass(documentStatus)
      }
      label={
        type == 'document' ? mapStatusToLabel(documentStatus) :
          mapMedicalDocumentsStatusToLabel(documentStatus)
      }
      icon={showInfoIcon ? setChipIcon(documentStatus) : undefined}
      sx={{
        '.MuiChip-deleteIcon': {
          color: mapStatusToChipIconColor(documentStatus),
          '&:hover': {
            color: mapStatusToChipIconColor(documentStatus),
            cursor: 'default',
          },
        },
      }}
      // in the MUI chip component, the end icon is called deleteIcon
      deleteIcon={showEditOffIcon ? <EditOffOutlined /> : undefined}
      // set onDelete to empty, which "hides" the delete icon
      onDelete={
        showEditOffIcon === false || documentStatus === 'DocumentSubmitted' || documentStatus === 'DocumentReceived'
          ? undefined
          : () => { }
      }
    />
  );
};

export default StatusChip;
