import { reduce, isEqual, isObject } from 'lodash';
import { setToast } from '../../redux/slices/globalToastSlice';

export const getDifferencesFromNestedObjects = (obj1, obj2) => {
    const differenceDictionary = reduce(obj1, (result, value, key) => {
      if (!isEqual(value, obj2[key])) {
        result[key] = isObject(value) && isObject(obj2[key])
          ? getDifferencesFromNestedObjects(value, obj2[key]) // Recurse for nested objects
          : value
      }
      return result;
    }, {});
    return differenceDictionary;
  }

  // demandHandlers.js
export const handleDemandEditingSave = async ({
  data = {},
  callbacks = {},
  setIsSaving,
  dispatch,
  saveDocumentData,
  triggerGetDocumentData,
  documentId
}) => {
  setIsSaving(true);

  try {
      const documentSaveSuccess = await saveDocumentData({ data }).unwrap();
      triggerGetDocumentData({ documentId, pollPdfStatus: true });

      if (documentSaveSuccess) {
          dispatch(setToast({
              message: 'Demand updated successfully',
              severity: 'success',
              isOpen: true,
          }));
      }
      callbacks.handleClose();
      setIsSaving(false);
  } catch (error) {
      dispatch(setToast({
          message: `Error updating demand: ${error.message}`,
          severity: 'error',
          isOpen: true,
      }));
      setIsSaving(false);
      callbacks.handleClose();
  }
};

