import { setApiErrorToast } from '../slices/globalToastSlice';

export const errorHandlingMiddleWare = ( options) => (next) => (action) => {
    const { dispatch } = options;

    if (action.type.endsWith('/rejected')) {
        console.error('API Error:', action);

        if (process.env.NODE_ENV === 'development' || (action?.meta?.aborted === false && action?.meta?.condition === false)) {
            const errorPayload = !(action?.type || '').includes('api/') ? action.payload : action;
            //checking for specific errors for custom toasts
            const message = buildCrucialErrorInfoString(errorPayload);

            const isFetchDocumentDatatRejected = action.type === 'Document/fetchDocumentData/rejected';
            const has403Status = errorPayload?.meta?.baseQueryMeta?.response?.status === 403 || errorPayload?.payload?.status === 403;

            if (isFetchDocumentDatatRejected && has403Status) {
                // fetchDocumentData 403 error handling
                dispatch(setApiErrorToast({ isOpen: true, customToastDisplay: 'fetchDocumentData403Toast' }));
            } else if (has403Status) {
                //generic 403 error handling
                dispatch(setApiErrorToast({ isOpen: true, customToastDisplay: 'generic403Toast' }));
            } else {
                //default error handling
                dispatch(setApiErrorToast({ isOpen: true, customToastDisplay: 'apiDefaultToast', message, errorPayload }));
            }
            console.error(message);
        }
    }
    return next(action);
};

const buildCrucialErrorInfoString = (errorResponse) => {
    let crucialInfo = '';

    if (errorResponse.error?.message) {
        crucialInfo += `Error: ${errorResponse.error.message}\n`;
    }
    if (errorResponse.meta?.baseQueryMeta?.response?.status) {
        crucialInfo += `Status: ${errorResponse.meta.baseQueryMeta.response.status}\n`;
    }
    if (errorResponse.meta?.arg?.endpointName) {
        crucialInfo += `Endpoint: ${errorResponse.meta.arg.endpointName}\n`;
    }
    if (errorResponse.meta?.baseQueryMeta?.request?.url) {
        crucialInfo += `URL: ${errorResponse.meta.baseQueryMeta.request.url}\n`;
    }
    if (errorResponse.meta?.arg?.originalArgs) {
        crucialInfo += `Original Args: ${JSON.stringify(errorResponse.meta.arg.originalArgs)}\n`;
    }

    return crucialInfo;
};
