import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../Transition';
import { Button } from '@mui/material';

const ConfirmationDialog = ({ handleCancel, handleOk, dialogOpen, dialogTitle, dialogText, cancelButtonText, okButtonText, buttonColor, additonalContent, enableOkButton = true }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xs'}
      open={dialogOpen}
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      {dialogTitle &&
        <DialogTitle>{dialogTitle}</DialogTitle>
      }
      <DialogContent
      >
        <DialogContentText id="alert-dialog-slide-description">{dialogText}</DialogContentText>
        {additonalContent && additonalContent}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={() => handleCancel()}
        >
          {cancelButtonText}
        </Button>{' '}
        <Button
          disabled={!enableOkButton}
          variant={'contained'}
          color={buttonColor || 'secondary'}
          onClick={() => {
            handleOk();
          }}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
