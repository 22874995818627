import React, { useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated, setUser, setUserData } from './redux/slices/userSlice';
import { getUserApi } from './api';

export const GlobalHooks = () => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.User);
    const { user } = useAuthenticator((context) => [context.user]);
    const hasAccessToken = Boolean(user?.signInUserSession?.accessToken);

    const getUserData = async (user) => {
        const userData = await getUserApi(user);
        dispatch(setUser(user))
        dispatch(setUserData(userData));
        dispatch(setIsAuthenticated(true));
    }

    useEffect(() => {
        if (user && hasAccessToken) {
            getUserData(user);
        }
    }, [user, hasAccessToken]);

    return (<> </>)
}

export default GlobalHooks;