import React from 'react';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { Stack, DialogActions, Button, InputAdornment, DialogTitle, DialogContent } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { cleanNumber, getCurrentDate, formatDecimal } from '../common';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { MEDICAL_CHARGES_INSIGHTS_TYPE } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';
import { useAddEntityInsightDetailsMutation } from '../services/insights/insightsApi';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';

const AddMedicalTreatment = () => {
  const dispatch = useDispatch();

  const { addMedicalTreatment } = useSelector((state) => state.GlobalDialogues);
  const { data } = addMedicalTreatment;
  const { documentId } = data;

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const [createMedicalTreatment] = useAddEntityInsightDetailsMutation();

  const validate = (values) => {
    const errors = {};
    !values.treatmentFacility && (errors.treatmentFacility = 'Required');
    !values.treatmentType && (errors.treatmentType = 'Required');
    !values.treatmentDate && (errors.treatmentDate = 'Required');
    !values.billedAmount && (errors.billedAmount = 'Required');
    !values.generatedPageNumber && (errors.generatedPageNumber = 'Required');

    const lastPage = documentFiles[documentFiles.length - 1]?.fileEntityData?.endPage;
    values.generatedPageNumber > lastPage && (errors.generatedPageNumber = 'Enter a valid page number');

    return errors;
  };

  const onSubmit = (values) => {
    const generatedPageNumber = values.generatedPageNumber ? parseInt(values.generatedPageNumber) : '';

    const file =
      documentFiles.find(
        ({ fileEntityData }) =>
          fileEntityData.startPage <= generatedPageNumber && fileEntityData.endPage >= generatedPageNumber
      ) || {};

    // Calculate file page number
    const page_number = generatedPageNumber - file?.fileEntityData?.startPage + 1 || 1;

    const newMedicalTreatment = {
      entityInsightId: values.medicalTreatmentId,
      entityInsightType: MEDICAL_CHARGES_INSIGHTS_TYPE,
      fileEntityId: file?.fileEntityId,
      entityInsightData: {
        diagnoses: values.diagnoses,
        treatments: values.treatments,
        boundingBox: [],
        page_number,
        billedAmount: cleanNumber(values.billedAmount),
        treatmentDate: values.treatmentDate,
        treatmentType: values.treatmentType,
        treatmentFacility: values.treatmentFacility.trim(),
      },
    };

    createMedicalTreatment({
      documentId,
      newEntityInsights: [newMedicalTreatment],
    })
      .unwrap()
      .catch((error) => {
        console.log('error', error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with adding medical treatment' }));
      });

    handleClose();
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <>
      <DialogTitle>Add medical treatment</DialogTitle>
      <Form
        onSubmit={onSubmit}
        initialValues={{ medicalTreatmentId: uuidv4() }}
        validate={validate}
        render={({ handleSubmit, form, valid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={2}>
                  <TextField label="Facility" name="treatmentFacility" required />

                  <TextField label="Type" name="treatmentType" required />

                  <TextField
                    label="Date of service"
                    name="treatmentDate"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ max: getCurrentDate() }}
                  />

                  <TextField
                    label="Charged amount"
                    name="billedAmount"
                    onChangeCapture={(e) => (e.target.value = formatDecimal(e.target.value))}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  />

                  <TextField label="Diagnoses" name="diagnoses" />

                  <TextField label="Treatments" name="treatments" />

                  <TextField
                    onChange={(e) => form.change('generatedPageNumber', e.target.value.replace(/[^0-9]/g, ''))}
                    label="Generated page number"
                    name="generatedPageNumber"
                    required
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button color="secondary" variant="text" onClick={handleClose}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="secondary" disabled={!valid}>
                  Save
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </>
  );
};

export default AddMedicalTreatment;
