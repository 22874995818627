import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import inventoryReducer from './slices/inventorySlice';
import contactLogInventoryReducer from './slices/contactLogInventorySlice';
import medicalsSlice from './slices/medicalsSlice';
import medicalsTreatmentsReducer from './slices/medicalsTreatmentsSlice';
import navbarSlice from './slices/navbarSlice';
import authorSlice from './slices/authorSlice';
import demandDomSlice from './slices/demandDomSlice';
import documentSlice from './slices/documentSlice';
import userHistoryPersistedSlice from './persistedSlices/userHistoryPersistedSlice';
import globalDialogueSlice from './slices/globalDialogueSlice';
import globalToastSlice from './slices/globalToastSlice';
import customerSlice from './slices/customerSlice';
import templateSlice from './slices/templateSlice';
import mriFindingsSlice from './slices/mriFindingsSlice';
import sideBySideWithVerticalNavSlice from './slices/sideBySideWithVerticalNavSlice';
import icdCodesSlice from './slices/icdCodesSlice';
import adminEditTemplateSlice from "./slices/adminEditTemplateSlice";
import templatePreviewSlice from './slices/templatePreviewSlice';
import codeEditorSlice from './slices/codeEditorSlice';
import userSlice from './slices/userSlice';
import editMenuSlice from './slices/editMenuSlice';
//RTK Query
import { apiSlice } from '../services/apiSlice';
import PdfApiCustomSlice from '../services/pdf/customSlice';
import AiNarrativeGenerationApiCustomSlice from '../services/aiNarrativeGeneration/customSlice'

import { errorHandlingMiddleWare } from './middleWare/apiErrorHandling';

const getStore = () => {
  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['UserHistoryPersisted'],
  };

  const rootReducer = combineReducers({
    Inventory: inventoryReducer,
    ContactLogInventory: contactLogInventoryReducer,
    MedicalsTreatments: medicalsTreatmentsReducer,
    Medicals: medicalsSlice,
    Navbar: navbarSlice,
    Author: authorSlice,
    DemandDom: demandDomSlice,
    Document: documentSlice,
    GlobalDialogues: globalDialogueSlice,
    GlobalToasts: globalToastSlice,
    UserHistoryPersisted: userHistoryPersistedSlice,
    Customer: customerSlice,
    Template: templateSlice,
    MriFindings: mriFindingsSlice,
    IcdCodes: icdCodesSlice,
    SideBySideWithVerticalNav: sideBySideWithVerticalNavSlice,
    AdminEditTemplate: adminEditTemplateSlice,
    TemplatePreview: templatePreviewSlice,
    CodeEditor: codeEditorSlice,
    User: userSlice,
    //RTK Query
    [apiSlice.reducerPath]: apiSlice.reducer,
    PdfCustomSlice: PdfApiCustomSlice,
    AiNarrativeGeneration: AiNarrativeGenerationApiCustomSlice,
    EditMenu: editMenuSlice,
  });

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  return configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(
          //RTK Query
          apiSlice.middleware,
          errorHandlingMiddleWare
        ),
  });
};

const store = getStore();

setupListeners(store.dispatch)

export default store;