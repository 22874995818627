import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { MRI_URL_PATH, selectPageInsightStatusOptions } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import { setMriSelectedRows } from '../redux/slices/mriFindingsSlice';
import { apiSlice } from '../services/apiSlice';

const EditMRIFinding = () => {
  const initialStatus = '';
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  const { editMRIFinding } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = editMRIFinding;
  const { documentId, user } = data;

  const { mriSelectedRows } = useSelector((state) => state.MriFindings);

  const isSameValue = mriSelectedRows.every((row) => row.value === mriSelectedRows[0].value);
  const initialValue = isSameValue ? mriSelectedRows[0].value : '';

  const [value, setValue] = useState(initialValue);

  const isDescriptionDirty = value !== initialValue;
  const isStatusDirty = selectedStatus !== initialStatus;

  const handleChangeStatus = (e) => setSelectedStatus(e.target.value);

  const handleChangeDescription = (e) => setValue(e.target.value);

  const handleSaveClick = async () => {
    setIsSaving(true);
    // Perform update
    const { error } = await dispatch(
      apiSlice.endpoints.updateEntityInsightDetails.initiate({
        documentId,
        user,
        insightsType: MRI_URL_PATH,
        entityIdsToUpdate: mriSelectedRows.flatMap(({ entityIds }) => entityIds),
        newStatus: selectedStatus,
        newValue: value
      })
    );

    if (error) {
      console.log('error', error);
      dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating MRI finding(s)' }));
    }

    dispatch(setMriSelectedRows([])); // Reset selections
    dispatch(resetGlobalDialogues()); // Close dialog
  };

  const handleClose = () => dispatch(resetGlobalDialogues());

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Edit MRI findings</DialogTitle>

      <DialogContent>
        <Alert severity="info" spacing={2} sx={{ my: 2 }}>
          {mriSelectedRows.length} selected
        </Alert>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <TextField
            label="Description"
            value={value}
            helperText={value ? '' : 'Multiple values'}
            fullWidth
            multiline
            maxRows={4}
            variant="outlined"
            onChange={handleChangeDescription}
          />

          <FormControl
            fullWidth
            size='small'
          >
            <InputLabel id="select-mri-status-label">Status</InputLabel>
            <Select
              labelId="select-mri-status-label"
              id="select-mri-status"
              value={selectedStatus}
              label="Status"
              onChange={handleChangeStatus}
            >
              {selectPageInsightStatusOptions.map(({ value, label }, index) => (
                <MenuItem key={index} value={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!isDescriptionDirty && !isStatusDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          {isSaving ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditMRIFinding;
