import React from 'react';
import { DialogContent, DialogContentText, DialogActions, Button, Typography, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MEDICAL_CHARGES_URL_PATH } from '../MedicalsComponents/insights';
import { useGetEntityInsightsQuery, useUpdateBatchPageInsightsMutation } from '../services/insights/insightsApi';
import { setToast } from '../redux/slices/globalToastSlice';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';

const DeleteMedicalTreatment = () => {
  const dispatch = useDispatch();

  const { deleteMedicalTreatment } = useSelector((state) => state.GlobalDialogues);
  const { data } = deleteMedicalTreatment;
  const { documentId, medicalTreatmentIds } = data;

  const { data: medicalCharges = [] } = useGetEntityInsightsQuery({
    documentId,
    insightsType: MEDICAL_CHARGES_URL_PATH,
  });

  const selectedMedicalTreatments = medicalCharges.filter(({ entityInsightId }) =>
    medicalTreatmentIds.includes(entityInsightId)
  );

  const [deleteMedical] = useUpdateBatchPageInsightsMutation({
    fixedCacheKey: 'update-medical-treatment',
  });

  const handleDeleteMedical = () => {
    const medicalTreatmentsToDelete = selectedMedicalTreatments.map((medicalTreatment) => ({
      ...medicalTreatment,
      entityInsightData: { ...medicalTreatment.entityInsightData, ignore: true },
    }));

    deleteMedical({ documentId, updatedInsights: medicalTreatmentsToDelete })
      .unwrap()
      .catch((error) => {
        console.log('error', error);
        dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with deleting medical treatment' }));
      });

    handleDeleteMedicalClose();
  };

  const handleDeleteMedicalClose = () => dispatch(resetGlobalDialogues());

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          {medicalTreatmentIds.length > 1 ? (
            <Typography variant="body1">
              {`You are about to permanently delete ${medicalTreatmentIds.length} medical treatments.`}
            </Typography>
          ) : (
            <>
              <Typography variant="body1">You are about to permanently delete: </Typography>
              <Typography sx={{ fontWeight: 'bold' }}>
                {selectedMedicalTreatments[0].entityInsightData.treatmentFacility} -{' '}
                {selectedMedicalTreatments[0].entityInsightData.treatmentType}
              </Typography>
            </>
          )}

          <Typography variant="body1">Are you sure you want to continue?</Typography>
        </Stack>
        <DialogContentText id="alert-dialog-slide-description"></DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleDeleteMedicalClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleDeleteMedical}>
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default DeleteMedicalTreatment;
