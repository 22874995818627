import React from 'react';
import { Grid, Tooltip, Stack, TextField as MuiTextField } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { TextField, Switches } from 'mui-rff';
import moment from 'moment';
import { MAX_DAYS_RELATIVE_DUE_DATE } from '../../../common';
export const DEFAULT_RELATIVE_DUE_DATE = 37;

const regenerationMessage = 'Values may update after regeneration';

const RelativeDaysField = ({ form, values, isLoading, spacing }) => (
    <TextField
        label="Relative days to respond"
        name="demandDetails.demandResponseRelativeDueDate"
        value={values?.demandDetails?.demandResponseRelativeDueDate}
        type="number"
        fullWidth
        InputLabelProps={{ shrink: true }}
        sx={spacing}
        disabled={!values?.demandDetails?.usingRelativeDueDate}
        // disabled={isLoading}  //do we want to disable this field when pdf is regenerating?
        helperText={isLoading ? regenerationMessage : ""}
        FormHelperTextProps={{ sx: { color: 'info.main' }, }}
        onChange={(e) => {
            //clip the input values to the range (1, MAX_DAYS_RELATIVE_DUE_DATE)
            const clampedValue = Math.min(Math.max(parseInt(e.target.value), 1), MAX_DAYS_RELATIVE_DUE_DATE);

            form.batch(() => {
                form.change('demandDetails.demandResponseRelativeDueDate', clampedValue);
                if (values?.demandDetails?.demandResponseDueDate !== '') {
                    form.change('demandDetails.demandResponseDueDate', '');
                }
            });
        }}
        onInput={(e) => {
            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Filter out non-integer values
        }}
        InputProps={{
            endAdornment: <Tooltip
                title="Using a relative due date will automatically calculate and adjust the due date until the demand is sent."
                arrow
                placement="top"
            >
                <div style={{ marginTop: '8px' }}>
                    <InfoOutlined color="action" />
                </div>
            </Tooltip>
        }}
    />
);

const UseRelativeDueDateSwitch = ({ form, isLoading, spacing, getCurrentDate, defaultRelativeDueDate }) => (
    <Stack direction="row" spacing={2} sx={spacing}>
        <Switches
            // disabled={isLoading}
            name="demandDetails.usingRelativeDueDate"
            onChange={(e) => {
                const DATE_FROM_NOW_UNIL_RELATIVE_DUE_DATE = moment(getCurrentDate()).add(defaultRelativeDueDate, 'days').format('YYYY-MM-DD');
                const checked = e.target.checked;

                form.batch(() => {
                    form.change('demandDetails.usingRelativeDueDate', checked);
                    form.change('demandDetails.demandResponseRelativeDueDate', checked ? defaultRelativeDueDate : '');
                    form.change('demandDetails.demandResponseDueDate', checked ? '' : DATE_FROM_NOW_UNIL_RELATIVE_DUE_DATE);
                });
            }}
            data={{ label: 'Use relative due date', value: true }}
            color="secondary"
        />
    </Stack>
);

const AllDemandResponseDueDateFields = ({ form, values, errors, initialValues, isPdfRegnerationLoading, gridPadding = 0, topMargin = '0px', defaultRelativeDueDate }) => {
    const getCurrentDate = () => moment().format('YYYY-MM-DD');

    return (
        <Grid container spacing={2} sx={{ p: gridPadding, mt: topMargin }}>
            <Grid item xs={12} md={6}>
                {values?.demandDetails?.usingRelativeDueDate === true ?
                    <RelativeDaysField
                        form={form}
                        values={values}
                        isLoading={isPdfRegnerationLoading}
                    />
                    :
                    <TextField
                        label="Demand response fixed due date"
                        name="demandDetails.demandResponseDueDate"
                        value={values?.demandDetails?.demandResponseDueDate}
                        error={errors?.demandDetails?.demandResponseDueDate}
                        type="date"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        helperText={errors?.demandDetails?.demandResponseDueDate || (isPdfRegnerationLoading ? regenerationMessage : "")}
                        FormHelperTextProps={{ sx: { color: 'info.main' }, }}
                        onChange={(e) => {
                            const newDate = e.target.value;
                            const formattedDate = moment(newDate).format('YYYY-MM-DD');
                            form.batch(() => {
                                form.change('demandDetails.demandResponseDueDate', formattedDate);
                                if (values?.demandDetails?.demandResponseRelativeDueDate !== '') {
                                    form.change('demandDetails.demandResponseRelativeDueDate', '');
                                }
                            });
                        }}
                        inputProps={{
                            min: getCurrentDate(),
                            max: moment().add(100, 'years').format('YYYY-MM-DD')
                        }}
                    />
                }
            </Grid>

            <Grid item xs={12} md={6}>
                <UseRelativeDueDateSwitch
                    form={form}
                    values={values}
                    isLoading={isPdfRegnerationLoading}
                    initialValues={initialValues}
                    getCurrentDate={getCurrentDate}
                    defaultRelativeDueDate={defaultRelativeDueDate}
                />
            </Grid>
        </Grid>
    );
};

export default AllDemandResponseDueDateFields;
