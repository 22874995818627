import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { UploadFileOutlined } from '@mui/icons-material';
import VisuallyHiddenInput from '../CommonComponents/VisuallyHiddenInput';
import { uploadFilesAndPostToDemand } from '../DemandComponents/utilities';
import { useDispatch } from 'react-redux';
import { fetchDocumentData } from '../redux/thunks/documentData';
import { setDocumentStatus } from '../redux/slices/documentSlice';
import { pollPdfRegenerationStatus } from '../services/pdf/thunks';
import { setPdfUrlPollingLoadingSkeleton } from '../services/pdf/customSlice';
import { DOCUMENT_UPLOAD_FILE_TYPES } from '../common-document';
import { apiSlice } from '../services/apiSlice';
import {
  DOCUMENT_UPLOADED,
  AWAITING_MEDICAL_EXTRACTION,
  WAITING_FIRM_APPROVAL,
  AWAITING_FIRM_REVIEW,
  AWAITNG_PRECEDENT_SIGNOFF,
} from '../common-demand';

const UploadDocumentsMenuItem = ({
  documentId,
  documentStatus,
  user,
  isDocumentUploading,
  setIsDocumentUploading,
  setUploadErrorState,
  uploadErrorState,
  handleMenuClose,
  showStatusDialog,
  showToastMessage
}) => {

  const dispatch = useDispatch();

  const handleSave = (documentId) => {
    dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
    dispatch(pollPdfRegenerationStatus({ documentId }));
    dispatch(fetchDocumentData({ documentId, user }))
      .then((response) => {
        dispatch(setDocumentStatus(response?.payload?.metadata?.documentStatus));
        setIsDocumentUploading(false);
      })
  }

  const handleFilesUpload = async (e) => {
    handleMenuClose(e);

    if (!e.target.files) {
      return;
    }

    const filesToUpload = [...e.target.files];
    for (const file of filesToUpload) {
      // 150MB
      if (file.size > 157286400) {
        showStatusDialog('Error: Files cannot be larger than 150MB', false);
        return;
      }
    }

    showToastMessage('Uploading file(s)...', 'info');
    setIsDocumentUploading(true);
    dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

    return uploadFilesAndPostToDemand(
      filesToUpload,
      documentId,
      user,
      setUploadErrorState,
      uploadErrorState,
      handleSave,
    )
  };

  const isUploadDocumentsDisabled =
    isDocumentUploading ||
    ![
      DOCUMENT_UPLOADED,
      AWAITING_MEDICAL_EXTRACTION,
      AWAITING_FIRM_REVIEW,
      WAITING_FIRM_APPROVAL,
      AWAITNG_PRECEDENT_SIGNOFF,
    ].includes(documentStatus);

  return (
    <MenuItem component="label" disabled={isUploadDocumentsDisabled}>
      <ListItemIcon>
        <UploadFileOutlined />
      </ListItemIcon>
      <ListItemText primary="Upload documents" />
      <VisuallyHiddenInput
        onChange={(e) => {
          handleFilesUpload(e);
        }}
        type="file"
        multiple
        accept={DOCUMENT_UPLOAD_FILE_TYPES}
      />
    </MenuItem>
  );
};

export default UploadDocumentsMenuItem;
