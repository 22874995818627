import { createSlice } from '@reduxjs/toolkit';

const initialMedicalsState = {
    medicalsLoading: false,
    medicals: {
        medicalTreatments: [],
    },
};

const medicalsSlice = createSlice({
    name: 'Medicals',
    initialState: initialMedicalsState,
    reducers: {
        setMedicalsTreatments: (state, action) => {
            state.medicals.medicalTreatments = action.payload;
        },
        setMedicalsLoading: (state, action) => {
            state.medicalsLoading = action.payload;
        },
        clearMedicalsState: () => initialMedicalsState,
    },
});

export const {
    setMedicalsTreatments,
    setMedicalsLoading,
    clearMedicalsState,
} = medicalsSlice.actions;

export default medicalsSlice.reducer;