import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { Transition } from '../Transition';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  TextField,
  CircularProgress,
  Checkbox,
  Autocomplete,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ICD_CODES_ENTITY_INSIGHTS_TYPE, INCLUDED_STATUS } from '../MedicalsComponents/insights';
import { setToast } from '../redux/slices/globalToastSlice';
import ICDCodeAutocomplete from '../MedicalsComponents/ICDCodes/ICDCodeAutocomplete';
import { v4 as uuidv4 } from 'uuid';
import { isDemandOrCoverLetter } from '../common-document';
import { apiSlice } from '../services/apiSlice';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';

const AddICDCode = () => {
  const dispatch = useDispatch();

  const { addICDCode } = useSelector((state) => state.GlobalDialogues);
  const { open, data } = addICDCode;
  const { documentId, user } = data;

  const [icdCodeFormValue, setIcdCodeFormValue] = useState(['', '']);
  const [icdCode, description] = icdCodeFormValue;

  const [pages, setPages] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  const isIcdCodeDirty = icdCode !== '';
  const isPageDirty = pages.length > 0;

  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const getPageOptions = () => {
    const filesWithoutCoverAndDemandLetter = documentFiles.filter(file => !isDemandOrCoverLetter(file.fileEntityData.detectedType))
    const startPage = filesWithoutCoverAndDemandLetter[0].fileEntityData.startPage
    const lastPage = filesWithoutCoverAndDemandLetter[filesWithoutCoverAndDemandLetter.length - 1]?.fileEntityData?.endPage;

    return Array.from({ length: (lastPage - startPage) + 1 }, (_, index) => startPage + index);
  };

  const sortedPages = pages.sort((a, b) => a - b);

  const handleIcdCodeChange = (value) => {
    if (Array.isArray(value)) {
      setIcdCodeFormValue(value);
    } else if (value === null) {
      setIcdCodeFormValue(['', '']);
    }
  };

  const handleSaveClick = async () => {
    setIsSaving(true);

    const newIcdEntities = pages.map((generatedPageNumber) => {
      const file = documentFiles.find(
        ({ fileEntityData }) => fileEntityData.startPage <= generatedPageNumber && fileEntityData.endPage >= generatedPageNumber
      ) || {};

      // Calculate file page number 
      const page_number = generatedPageNumber - file?.fileEntityData?.startPage + 1 || 1;

      return {
        entityInsightId: uuidv4(),
        entityInsightType: ICD_CODES_ENTITY_INSIGHTS_TYPE,
        fileEntityId: file?.fileEntityId,
        entityInsightData: {
          icd_code_id: uuidv4(),
          value: icdCode,
          description,
          status: INCLUDED_STATUS,
          needs_review: false,
          needs_review_reason_internal: [],
          bboxes: [],
          page_number,
        },
      };
    });

    const { error } = await dispatch(
      apiSlice.endpoints.addEntityInsightDetails.initiate({
        documentId,
        user,
        newEntityInsights: newIcdEntities,
      })
    );

    if (error) {
      console.log('error', error);
      dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with adding ICD code' }));
    }

    dispatch(resetGlobalDialogues());
  };

  const handleClose = () => {
    dispatch(resetGlobalDialogues());
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Dialog open={open} onClose={handleClose} fullWidth TransitionComponent={Transition}>
      <DialogTitle>Add ICD code</DialogTitle>

      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          <ICDCodeAutocomplete value={icdCodeFormValue} onChange={handleIcdCodeChange} user={user} />
          <Autocomplete
            id="select-page-number"
            multiple
            limitTags={5}
            disableCloseOnSelect
            options={getPageOptions()}
            value={sortedPages}
            onChange={(e, value) => {
              setPages(value);
            }}
            getOptionLabel={(option) => option.toString()}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                  {option}
                </li>
              );
            }}
            renderInput={(params) => <TextField {...params} label="Page" />}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>

        <Button
          disabled={!isIcdCodeDirty || !isPageDirty}
          onClick={handleSaveClick}
          variant="contained"
          color="secondary"
        >
          {isSaving ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddICDCode;
