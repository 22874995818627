import { apiSlice } from '../apiSlice';
import { setPdfUrlPollingLoadingSkeleton } from '../pdf/customSlice';
import { pollPdfRegenerationStatus } from '../pdf/thunks';
import {
  ICD_CODES_URL_PATH,
  MEDICAL_CHARGES_URL_PATH,
  MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE,
  NEEDS_REVIEW_STATUS,
} from '../../MedicalsComponents/insights';
import { transformInsightMedicalsToFileMedicalsData } from '../../MedicalsComponents/medicals';
import { setTreatmentProviderChartLegend } from '../../redux/slices/medicalsTreatmentsSlice';
import { setMedicalsTreatments } from '../../redux/slices/medicalsSlice';

const insightsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET /document/{documentId}/entityInsights/{insightsType}
    getEntityInsights: builder.query({
      query: ({ documentId, insightsType }) => ({
        url: `/document/${documentId}/entityInsights/${insightsType}`,
        method: 'GET',
      }),
      onQueryStarted: async ({ insightsType }, { dispatch, queryFulfilled }) => {
        try {
          if (insightsType === MEDICAL_CHARGES_URL_PATH) {
            const { data: medicalCharges = [] } = await queryFulfilled;
            // Transform the new medicals data format into the old format as a transition
            const transformedMedicals = transformInsightMedicalsToFileMedicalsData(medicalCharges);

            const uniqueTreatmentNamesArray = (transformedMedicals?.medicalTreatments || [])
              .map((row) => (row?.treatmentFacility||'').toUpperCase())
              .filter((value, index, self) => self.indexOf(value) === index);
            const newLegend = uniqueTreatmentNamesArray.reduce((acc, value) => {
              acc[value] = true;
              return acc;
            }, {});

            dispatch(setTreatmentProviderChartLegend(newLegend));
            dispatch(setMedicalsTreatments(transformedMedicals?.medicalTreatments || []));
          }
        }
        catch (error) {
          return { error };
        }
      },
      providesTags: (result, _error, { insightsType }) =>
        // is result available?
        result
          ? // successful query
          [
            ...result.map(({ value }) => ({ type: 'EntityInsight', id: value })),
            { type: 'EntityInsight', id: `ENTITY_INSIGHT_${MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE[insightsType]}` },
          ]
          : // an error occurred, but we still want to refetch this query when this type is invalidated
          [{ type: 'EntityInsight', id: `ENTITY_INSIGHT_${MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE[insightsType]}` }],
    }),

    // POST /document/{documentId}/entityInsights/{insightsType}/details
    getEntityInsightsDetails: builder.query({
      query: ({ documentId, insightsType, insightValue, insightEntityIds }) => ({
        url: `/document/${documentId}/entityInsights/${insightsType}/details`,
        method: 'POST',
        body: JSON.stringify(insightValue ? { insightValue } : { insightEntityIds }),
      }),
      providesTags: (result, _error, { insightsType }) =>
        result
          ? // successful query
          [
            ...result.map(({ entityInsightId }) => ({ type: 'EntityInsightsDetail', id: entityInsightId })),
            {
              type: 'EntityInsightsDetail',
              id: `ENTITY_INSIGHT_DETAIL_${MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE[insightsType]}`,
            },
          ]
          : // an error occurred, but we still want to refetch this query when this type is invalidated
          [
            {
              type: 'EntityInsightsDetail',
              id: `ENTITY_INSIGHT_DETAIL_${MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE[insightsType]}`,
            },
          ],
    }),

    // Custom endpoint function that fetches entity insight details for multiple insight values
    getMultipleEntityInsightsDetails: builder.query({
      queryFn: async ({ documentId, insightsType, insightValues }, { dispatch }) => {
        try {
          const entityInsightsDetails = (
            await Promise.all(
              insightValues.map((insightValue) =>
                dispatch(
                  apiSlice.endpoints.getEntityInsightsDetails.initiate({ documentId, insightsType, insightValue })
                )
              )
            )
          ).flatMap((icdDetails) => icdDetails.data);

          return { data: entityInsightsDetails };
        } catch (error) {
          dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: false }));
          return { error };
        }
      },
    }),

    // GET /document/{documentId}/insights/{insightsType}
    getDocumentInsights: builder.query({
      query: ({ documentId, insightsType }) => ({
        url: `/document/${documentId}/insights/${insightsType}`,
        method: 'GET',
      }),
      providesTags: (result, _error, { insightsType }) =>
        result
          ? // successful query
          [
            { type: 'DocumentInsights', id: result.entityId },
            { type: 'DocumentInsights', id: `DOCUMENT_INSIGHT_${MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE[insightsType]}` },
          ]
          : // an error occurred, but we still want to refetch this query when this type is invalidated
          [{ type: 'DocumentInsights', id: `DOCUMENT_INSIGHT_${MAP_ENTITY_INSIGHT_URL_PATH_TO_TYPE[insightsType]}` }],
    }),

    // POST /document/{documentId}/batchPageInsights
    updateBatchPageInsights: builder.mutation({
      query: ({ documentId, updatedInsights }) => ({
        url: `/document/${documentId}/batchPageInsights`,
        method: 'POST',
        body: JSON.stringify(updatedInsights),
      }),
      onQueryStarted: async ({ documentId }, { dispatch, queryFulfilled }) => {
        try {
          // Wait for request to finish, then refresh data
          await queryFulfilled;

          dispatch(apiSlice.endpoints.getAllFilesByDocumentId.initiate(documentId, { forceRefetch: true }));
          dispatch(pollPdfRegenerationStatus({ documentId }));
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: (_result, _error, { updatedInsights }) => [
        ...updatedInsights.map(({ entityInsightId }) => ({ type: 'EntityInsightsDetail', id: entityInsightId })),
        {
          type: 'EntityInsight',
          id: `ENTITY_INSIGHT_${updatedInsights[0].entityInsightType}`,
        },
        {
          type: 'EntityInsightsDetail',
          id: `ENTITY_INSIGHT_DETAIL_${updatedInsights[0].entityInsightType}`,
        },
        {
          type: 'DocumentInsights',
          id: `DOCUMENT_INSIGHT_${updatedInsights[0].entityInsightType}`,
        },
      ],
    }),

    // Custom mutation to add a new entity insight
    addEntityInsightDetails: builder.mutation({
      queryFn: async ({ documentId, newEntityInsights }, { dispatch }) => {
        try {
          // Send update
          await dispatch(
            apiSlice.endpoints.updateBatchPageInsights.initiate({ documentId, updatedInsights: newEntityInsights })
          ).unwrap();

          return { data: true };
        } catch (error) {
          return { error };
        }
      },
    }),

    // Custom mutation that fetches the insight details in order to build the payload for the update
    updateEntityInsightDetails: builder.mutation({
      queryFn: async (
        {
          documentId,
          insightsType,
          insightValues,
          entityIdsToUpdate,
          newStatus = '',
          newValue = '',
          newDescription = '',
        },
        { dispatch }
      ) => {
        try {
          // Set PDF loading state
          dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

          // Fetch entity insight details to build payload
          // Temporary stopgap is to fetch by insightValue for ICD codes or by insightEntityIds for MRI
          const entityInsightDetailsPromise =
            insightsType === ICD_CODES_URL_PATH
              ? dispatch(
                apiSlice.endpoints.getMultipleEntityInsightsDetails.initiate(
                  {
                    documentId,
                    insightsType,
                    insightValues,
                  },
                  { forceRefetch: true }
                )
              )
              : dispatch(
                apiSlice.endpoints.getEntityInsightsDetails.initiate(
                  {
                    documentId,
                    insightsType,
                    insightEntityIds: entityIdsToUpdate,
                  },
                  { forceRefetch: true }
                )
              );

          const { data: entityInsightDetails } = await entityInsightDetailsPromise;
          entityInsightDetailsPromise.unsubscribe();

          // Only include the entity ids that need to be updated
          const entityInsightsToUpdate = entityInsightDetails.filter(({ entityInsightId }) =>
            entityIdsToUpdate.includes(entityInsightId)
          );

          // Construct payload
          const payload = entityInsightsToUpdate.map(({ entityInsightData, ...entityInsightDetail }) => {
            return {
              ...entityInsightDetail,
              entityInsightData: {
                ...entityInsightData,
                ...(newStatus && { status: newStatus }),
                ...(newStatus !== NEEDS_REVIEW_STATUS && { needs_review: false }),
                ...(newValue && { value: newValue }),
                ...(newDescription && { description: newDescription }),
              },
            };
          });

          // Send update
          await dispatch(
            apiSlice.endpoints.updateBatchPageInsights.initiate({ documentId, updatedInsights: payload })
          ).unwrap();

          return { data: true };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetEntityInsightsQuery,
  useGetEntityInsightsDetailsQuery,
  useGetMultipleEntityInsightsDetailsQuery,
  useGetDocumentInsightsQuery,
  useUpdateBatchPageInsightsMutation,
  useAddEntityInsightDetailsMutation,
  useUpdateEntityInsightDetailsMutation,
} = insightsApi;
