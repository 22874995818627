import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Autocomplete, TextField, Stack, IconButton, Snackbar, Alert, CircularProgress, Typography, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resetGlobalDialogues } from '../redux/slices/globalDialogueSlice';
import { CloudUploadOutlined } from '@mui/icons-material';
import { getUploadMedicalsUrl, uploadMedicalsApi, pushDataToS3 } from '../api';
import { convertUTCDateTimeToLocalDate, convertUTCDateTimeToLocalTime } from '../common';
import moment from 'moment';
import { pollPdfRegenerationStatus } from '../services/pdf/thunks';
import { setPdfUrlPollingLoadingSkeleton } from '../services/pdf/customSlice';
import { useAuthenticator } from '@aws-amplify/ui-react';

const UploadMedicals = () => {

    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [uploadedFile, setUploadedFile] = useState([]);
    const [batch, setBatch] = useState(null);
    const [toastState = {
        open: false,
        message: '',
        severity: 'success'
    }, setToastState] = useState();

    const { user } = useAuthenticator((context) => [context.user]);

    const [errorState, setErrorState] = useState({
        batchId: '',
        file: ''
    });

    const { uploadMedicals } = useSelector(state => state.GlobalDialogues);
    const { open, data } = uploadMedicals;
    const { documentId } = data;

    const { batches } = useSelector((state) => state.Document);

    const maxFileSize = 157286400;
    const hasFileUploaded = !!uploadedFile?.name
    const fileError = uploadedFile?.size > maxFileSize ? 'File size cannot exceed 150MB' : !hasFileUploaded ? 'File is required' : '';

    const handFileChange = (e) => {
        const file = e.target.files[0];
        setErrorState({ ...errorState, file: file.size > maxFileSize ? 'File size cannot exceed 150MB' : '' });
        setUploadedFile(file);
    }

    const handleUploadMedicals = async () => {

        setIsSaving(true);
        setToastState({
            open: true,
            message: 'Storing medicals...',
            severity: 'info'
        })

        const fileExtension = uploadedFile.name.split('.').pop();

        const uploadMedicalsUrlResponse = await getUploadMedicalsUrl(data.documentId, batch.batchEntityId, fileExtension, data.user)
            .then(response => response.json());

        const success = await pushDataToS3(uploadMedicalsUrlResponse['uploadFields'], uploadedFile)
            .then(response => {
                setToastState({
                    open: true,
                    message: 'Medicals stored successfully, attaching to demand...',
                    severity: 'success'
                })
                return response
            })
            .catch(error => {
                setToastState({
                    open: true,
                    message: 'An error occurred while storing medicals',
                    severity: 'error'
                })
                return false;
            });


        if (success) {

            const uploadMedicalsResponse = await uploadMedicalsApi(documentId, batch.batchEntityId, fileExtension, data.user)
                .then(response => response.json())
                .catch(error => {
                    setToastState({
                        open: true,
                        message: 'An error occurred while attaching medicals to demand',
                        severity: 'error'
                    })
                    return false;
                });

            if (uploadMedicalsResponse?.document?.documentId) {
                setToastState({
                    open: true,
                    message: 'Medicals attached to demand successfully, regenerating document...',
                    severity: 'success'
                })
                dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));
                dispatch(pollPdfRegenerationStatus({ documentId }));
                setTimeout(() => {
                    dispatch(resetGlobalDialogues());
                }, 4000);

            } else {
                setToastState({
                    open: true,
                    message: 'An error occurred while attaching medicals to demand',
                    severity: 'error'
                })
            }
        }
    }

    const handleBatchChange = (event, newValue) => {
        setBatch(newValue);
        setErrorState({ ...errorState, batchId: newValue ? '' : 'Batch ID is required' });
    };

    const handleClose = () => dispatch(resetGlobalDialogues());

    return (

        <Dialog open={open}
            onClose={handleClose}
            fullWidth
        >

            <DialogTitle>Upload medicals</DialogTitle>

            <DialogContent>
                <Stack spacing={2} sx={{ mt: 2 }} >

                    <TextField
                        value={uploadedFile?.name || ''}
                        required
                        fullWidth
                        label="Select file"
                        variant="outlined"
                        error={!!errorState.file}
                        helperText={errorState.file}
                        onBlur={e => setErrorState({ ...errorState, file: fileError })}
                        InputProps={{
                            endAdornment:
                                <IconButton
                                    sx={{
                                        "&.MuiButtonBase-root:hover": {
                                            bgcolor: "transparent"
                                        },
                                        padding: 0
                                    }}
                                >
                                    <Button
                                        startIcon={<CloudUploadOutlined />}
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        component="label"
                                        onBlur={e => setErrorState({ ...errorState, file: fileError })}
                                    >
                                        {hasFileUploaded ? 'Replace' : 'Select file'}
                                        <input
                                            accept=".csv,.xlsx"
                                            type="file"
                                            hidden
                                            onChange={handFileChange}
                                        />
                                    </Button>
                                </IconButton>

                        }}
                    />
                    <Autocomplete
                        id="select-batch"
                        options={batches.map(({ batchEntityId, createdDate }) => ({
                            batchEntityId,
                            createdDate: moment.utc(createdDate),
                        }))}
                        value={batch}
                        onChange={handleBatchChange}
                        onBlur={e => setErrorState({ ...errorState, batchId: e.target.value ? '' : 'Batch ID is required' })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required
                                label="Select batch"
                                error={!!errorState.batchId}
                                helperText={errorState.batchId || (batch?.createdDate
                                    ? `${convertUTCDateTimeToLocalDate(batch.createdDate)} ${convertUTCDateTimeToLocalTime(batch.createdDate)}`
                                    : '')
                                }
                            />
                        )}
                        getOptionLabel={(option) => option.batchEntityId}
                        isOptionEqualToValue={(option, value) => option.batchEntityId === value.batchEntityId}
                        renderOption={({ key, ...optionProps }, option) => (
                            <li key={key} {...optionProps}>
                                <Stack>
                                    {option.batchEntityId}
                                    <Typography variant="tableP1">
                                        {convertUTCDateTimeToLocalDate(option.createdDate)}{' '}
                                        {convertUTCDateTimeToLocalTime(option.createdDate)}
                                    </Typography>
                                </Stack>
                            </li>
                        )}
                    />

                </Stack>

                <Snackbar
                    open={toastState.open}
                    autoHideDuration={10000}
                    onClose={e => setToastState({ ...toastState, open: false })}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Paper elevation={8}>
                        <Alert onClose={e => setToastState({ ...toastState, open: false })} severity={toastState.severity} sx={{ width: '100%' }}>{toastState.message}</Alert>
                    </Paper>
                </Snackbar>

            </DialogContent>

            <DialogActions>

                <Button
                    onClick={handleClose}
                    variant='text'
                    color="secondary"
                >
                    Cancel
                </Button>

                <Button
                    disabled={!!errorState.batchId || !!errorState.file || isSaving || !hasFileUploaded || !batch}
                    onClick={handleUploadMedicals}
                    variant="contained"
                    color="secondary"
                >
                    {isSaving ? <CircularProgress size={24} /> : 'Upload'}
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default UploadMedicals;