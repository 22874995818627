
import { useNavigate } from 'react-router-dom';
import React, { useEffect } from "react";
import { useAuthenticator } from '@aws-amplify/ui-react';
import Container from '@mui/material/Container';
import { CircularProgress, Grid, ThemeProvider } from '@mui/material';
import { theme } from './Theme'
import { getUserApi } from './api';
import { userHasRole } from './common';
import { isLawFirmUser, isPrecedentUser } from './common-roles';

export const DefaultRoute = (props) => {

    const navigate = useNavigate()

    const { route, signOut, user } = useAuthenticator((context) => [
        context.route,
        context.signOut,
        context.user
    ]);


    const getUser = async () => {
        const userData = await getUserApi(user);

        if (isLawFirmUser(userData)) {
            navigate('/inventory');
        } else if (isPrecedentUser(userData)) {
            if (userHasRole('PrecedentCaller', userData)) {
                navigate('/contact-log-inventory');
            } else {
                navigate('/inventory');
            }
        } else {
            navigate('/login');
        }
    };


    // Triggers on page load or shortCode change
    useEffect(() => {

        getUser();

    }, []);


    return (
        <Container maxWidth='100'>
            <ThemeProvider theme={theme}>
                <>
                    <div style={{ position: 'fixed', zIndex: 1, top: 63, width: '97%', backgroundColor: "#FFFFFF" }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ minHeight: "100vh" }}
                        >
                            <Grid item>
                                <CircularProgress size={80} />
                            </Grid>
                        </Grid>
                    </div>
                </>
            </ThemeProvider>
        </Container>
    );
};
